<template>
  <!--  <div>-->
  <!--    <div @click="onClickFlag($event)">-->
  <!--      <CIcon-->
  <!--        :name="`cif-${$i18n.locale}`"-->
  <!--        height="25"-->
  <!--      />-->
  <!--    </div>-->
  <!--    <select v-model="$i18n.locale" @change="onChange($event)" ref="languageSelector">-->
  <!--      <option-->
  <!--        v-for="(lang, i) in langs"-->
  <!--        :key="`lang-${i}`"-->
  <!--        :value="lang">-->
  <!--        {{ lang }}-->
  <!--      </option>-->
  <!--    </select>-->
  <!--  </div>-->
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0 pb-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon
          :name="`cil-language`"
          height="25"
        />
      </CHeaderNavLink>
    </template>
    <CDropdownItem
      v-for="(lang, i) in langs"
      :key="`lang-${i}`"
      :value="lang.value"
      v-on:click="onClickLang($event, lang)"
    >
      {{ lang.label }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Consts from "@/utils/constants";

export default {
  name: 'SwitchLocale',
  data() {
    return { langs: [
        {value: 'fr', label: 'Français'},
        {value: 'zh', label: '中文'},
        {value: 'en', label: 'English'},
      ]
    }
  },
  methods: {
    onClickLang(event, lang) {
      this.$i18n.locale = lang.value
      localStorage.setItem(Consts.localStorage.languageKey, lang.value);
      document.location.reload();
    }
  }
}
</script>
