<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CImg
        class="c-sidebar-brand-full"
        src="img/logo-white.png"
      />
      <CImg
        class="c-sidebar-brand-minimized"
        src="img/logo-white-minimized.png"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="computedSidebar"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import AuthService from "@/services/auth.service";

export default {
  name: 'TheSidebar',
  nav,
  methods: {
    filterMenuForAuthUserType () {
      this.$options.nav[0]._children = this.$options.nav[0]._children.filter(item => {
        return !item.roles || item.roles.includes(AuthService.userType());
      });
    },

    localizeMenu () {
      this.$options.nav[0]._children.map(item => {
        item.name = this.$t(item.name)

        if (item._children && item._children[0]) item._children[0] = this.$t(item._children[0])
      })
    }
  },

  computed: {
    computedSidebar () {
      this.filterMenuForAuthUserType()
      this.localizeMenu()

      return this.$options.nav
    },
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>
