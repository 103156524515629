<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CImg
        src="img/logo-colored.png"
      />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <SwitchLanguage/>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import CBreadcrumbRouter from '../views/overides/CBreadcrumbRouter'
import SwitchLanguage from '../views/components/SwitchLanguage.vue'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    CBreadcrumbRouter,
    SwitchLanguage
  }
}
</script>
