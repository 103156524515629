import {AuthEnum} from "@caocao-mobility/ccmp-enum";
import _c from "lodash/collection";

let Roles = {
  CaocaoUser: [
    AuthEnum.Type.CEEU_CENTER_USER,
    AuthEnum.Type.ZONE_ADMIN_USER,
    AuthEnum.Type.ZONE_USER
  ],
  AllUser: _c.map(AuthEnum.Type)
}

export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'nav.global',
        to: '/global',
        icon: 'cil-home',
        roles: [...Roles.CaocaoUser]
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['nav.today.label']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'nav.today.b2c',
        to: '/b2c',
        icon: 'cil-people',
        roles: [...Roles.CaocaoUser]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'nav.today.b2b',
        to: '/b2b',
        icon: 'cil-calendar',
        roles: [...Roles.CaocaoUser]
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['nav.operation.label']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'nav.operation.subcontractors',
        to: '/operation/subcontractors',
        icon: 'cil-people'
      }
    ]
  }
]
