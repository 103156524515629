<template>
  <CFooter :fixed="false">
    <div class="ml-auto">
      <a href="https://caocao.fr" target="_blank">Caocao</a>
      <span class="ml-1">- MyReport {{new Date().getFullYear()}}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
